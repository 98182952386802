.footer {
  width: 90vw;
  margin: 0 auto;
  background-color: var(--background-web);
  padding: 2rem 0;
}

.footer-email,
.footer-links,
.footer-copyright {
  font-size: 1rem;
  font-family: var(--title);
  text-decoration: none;
  display: block;
  color: var(--color-text);
}

.footer-links {
  display: inline-block;
  letter-spacing: 1px;
}

.footer-dots {
  color: var(--color-text);
  padding: 0 .5rem;
}
