:root {
  --fontSystem: BlinkMacSystemFont, -apple-system, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', Helvetica, sans-serif;
  --monospace: monospace;
  --text: 'Inter', sans-serif;
  --background-web: #FBD9CF;
  --f1: #f1f1f1;
  --background: #ED7680;
  --background-element: #ED7680;
  --color-text: #392020;
  --negro: #111;
  --shadow: 0 4px 6px 0 hsla(0, 0%, 0%, .25);
}
