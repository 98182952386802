.renta-media-map {
  position: relative;
}

.distritos {
  fill: #473a5f;
  stroke: #81165a;
  stroke-opacity: .1;
  cursor: pointer;
  transition: all .3s ease-in;
}

#map {
  width: 100%;
  height: 100vh;
  position: relative;
  margin: auto;

  svg {
    position: absolute;
    width: 100%;
    height: 100%;
  }
}

.distrito {
  margin: 1rem 0;

  .domain {
    fill: none;
    stroke: none;
  }

  .yAxis {
    .domain {
      stroke: none;
    }

    line {
      stroke: #111;
      stroke-dasharray: 3px;
    }

    text {
      display: none;
    }
  }

  .xAxis {
    .domain {
      stroke-width: 2px;
    }
  }

  text {
    font-family: var(--condensed);
    font-size: 12px;
  }

  text.nombre-distrito {
    font-size: 16px;
    display: inline-block;
    font-weight: 600;
  }

  .tooltip-porcentaje {
    font-weight: 600;
  }

  &:nth-child(3n+1) {
    .yAxis text {
      display: inline-block;
    }
  }

  .nombre-distrito {
    font-size: 12px;
  }
}

.distritos-renta {
  fill: rgba(255, 255, 255, .1);
  stroke: #1b2156;
  stroke-opacity: .3;
  cursor: pointer;
  transition: all ease-in .3s;

  &:hover {
    stroke-width: 1;
    fill: #473a5f;
    fill-opacity: .8;
  }
}

.tooltip {
  font-weight: bold;
  position: absolute;
  top: 5vh;
  right: 2rem;
  min-width: 20rem;
  height: 5rem;
  color: #111;
  background-color: var(--background-web);
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .18);
}

.tooltip-titulo {
  font-size: 1.25rem;
  font-family: var(--rubik);
  padding: .25rem .5rem;
  margin: 0;
}

.container-tooltip {
  padding: .5rem;
  padding-top: 0;
}

.renta-text {
  font-size: 12px;
  font-weight: normal;
  font-family: var(--plex);
  color: var(--negro);
}

.renta-media-title {
  font-size: 2rem;
  font-family: var(--rubik);
}

.renta-media-subtitle {
  font-size: 1rem;
  color: var(--negro);
  font-family: var(--plex);
  max-width: 40rem;
}

.renta-numero {
  font-size: 14px;
  font-family: var(--plex);
  color: var(--negro);
  font-weight: bold;
}

.chart-container {
  max-width: 90vw;
  margin: 2rem auto 6rem;
}

.bar-bgc5 {
  fill: #8A9DA4;
}

.clau-container {
  margin: 0 auto;
  width: 100%;
  padding: 2rem 0;
}

.axis-x,
.axis-y {
  .domain {
    stroke: none;
  }

  .tick line {
    stroke-dasharray: 3px 3px;
  }

  text {
    font-family: var(--plex);
    font-size: 11px;
  }
}

@media only screen and (min-width: 48em) {

  .chart-container {
    max-width: 60rem;
  }

}
