.bio {
  margin: 0 auto;
  max-width: 90vw;
  color: var(--font);
}

.bio-element {
  width: max-content;
  max-width: 25rem;
}

.bio-element-title {
  font-size: 2.5rem;
  margin: 1rem 0;
  font-weight: bold;
  font-family: var(--title);
}

.bio-element-subtitle {
  font-size: 1.25rem;
  margin: 0;
  font-family: var(--fontSystem);
}

.bio-element-text {
  font-size: 1rem;
  font-family: var(--title);
  margin: 0 0 1rem;
  max-width: 35rem;
  width: 100%;

  .trough {
    text-decoration: line-through;
  }
}

.charla-element {
  width: 100%;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }
}

.charla-element-year {
  font-size: 2rem;
  font-weight: bold;
  font-family: var(--title);
  display: block;
  margin-bottom: .25rem;
}

.charla-element-title {
  font-size: 1.5rem;
  font-weight: bold;
  display: block;
}

.charla-element-text {
  color: var(--color-text);
  margin-bottom: .75rem;
  display: inline-block;
}

.charla-element-link {
  font-size: 1rem;
  display: inline-block;
  vertical-align: middle;
}

@media only screen and (min-width: 48rem) {

  .bio {
    max-width: 42rem;
  }

  .bio-element {
    width: 100%;
    padding: 2rem 0;
  }

}

@media only screen and (min-width: 64rem) {

  .bio {
    max-width: 58rem;
  }

}
