* {
  box-sizing: border-box;
  line-height: 1.5;
}

html {
  background-color: var(--background-web);
  color: var(--color-text);
  font-family: var(--text);
}

a {
  color: var(--color-text);
  font-weight: bold;
}

.project {
  width: 90vw;
  margin: 0 auto;
}

.project-title {
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  margin-top: 0;
  color: var(--color-text);
}

.project-grid {
  display: grid;
  margin: 2rem auto;
  margin-top: 0;
  grid-template-columns: 1fr;
  gap: 4rem;
}

.project-element {
  display: grid;
  place-content: space-between;
  text-decoration: none;
  color: var(--background);
  background: var(--background);
  padding: 1.5rem;
  border-radius: 4px;
  transition: background .3s ease-in;
  box-shadow: var(--shadow);
  &:hover {
    background: #bdd7e4;
  }
}

.project-element-title {
  font-size: 1.25rem;
  color: var(--color-text);
  margin: 0;
  padding-bottom: 0;
}

.project-element-subtitle {
  font-size: 1rem;
  font-weight: normal;
  padding: .5rem 0 1rem;
  display: block;
  color: var(--color-text);
}

.project-year {
  font-size: 14px;
  font-weight: bold;
  display: inline-block;
  font-family: var(--monospace);
  color: var(--color-text);
}

@media only screen and (min-width: 48em) {

  .project-grid {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

}

@media only screen and (min-width: 64em) {

  .project-grid,
  .project,
  .footer {
    max-width: 60rem;
  }

}
